/**
*
* Placeholder for SubscribeOverlay
*
*/

// The actual component
const SubscribeOverlay = () => (null)

export default SubscribeOverlay
