/**
*
* Placeholder for Subscribe
*
*/

// The actual component
const Subscribe = () => (null)

export default Subscribe
