/**
*
* Placeholder for SubscribeButton
*
*/

// The actual component
const SubscribeButton = () => (null)

export default SubscribeButton
