/**
*
* Placeholder for Comments
*
*/

// The actual component
const Comments = () => (null)

export default Comments
