/**
*
* Placeholder for TableOfContents
*
*/

// The actual component
const TableOfContents = () => (null)

export default TableOfContents
